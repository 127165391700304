.manual {
  min-width: 700px;
}

.main {
  margin: 40px 50px 64px;
}

.card {
  margin-top: 40px;
  border-radius: 8px;
  background-color: rgb(255, 255, 255);
  padding: 30px;
  min-height: calc(100vh - 184px);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card img{
  display: block;
  width: 100%;
  max-width: 800px;
}

@media screen and (max-width: 1350px) {
    .main {
        margin: 0px 30px 64px;
      }
}