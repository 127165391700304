.app-header {
  width: 100%;
  display: flex;
  align-items: center;
}
.app-header .logo {
  display: flex;
  align-items: center;
  cursor: pointer;
  flex: 0 0 auto;
}
.app-header .logo .icn {
  width: 28px;
  height: 28px;
  border-radius: 50%;
}
.app-header .logo .name {
  font-size: 16px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.9);
  margin-left: 8px;
  font-family: "PingFangSC-Semibold";
}
.app-header .tabs {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
}
.app-header .tabs .tab {
  font-size: 16px;
  margin-left: 4rem;
  color: rgba(0, 0, 0, 0.9);
}
.app-header .tabs .tab.active {
  color: #ff6246;
  font-weight: 600;
}
.app-header .service {
  display: flex;
  align-items: center;
  font-size: 12px;
  margin-left: 6rem;
  flex: 0 0 auto;
}
.app-header .service .tag {
  padding: 4px 6px;
  font-weight: bold;
  border-radius: 2px;
  margin-right: 10px;
}
.app-header .service .tag.red {
  background-color: rgba(255, 98, 70, 0.2);
  color: #ff6246;
}
.app-header .service .tag.green {
  background-color: rgba(99, 178, 16, 0.2);
  color: #63b210;
}
.app-header .service .renew {
  color: #ff6246;
  font-weight: bold;
  text-decoration: underline;
  margin-left: 4px;
  cursor: pointer;
}
.app-header .pending {
  flex: 1 1 auto;
}
.app-header .opts {
  margin-left: 6rem;
  flex: 0 0 auto;
}
.app-header .opts .opt {
  margin-left: 12px;
  border-radius: 6px;
  font-weight: 600;
  border: solid 1px rgba(0, 0, 0, 0.1);
}
.app-header .opts .opt.primary {
  border-color: #ff6246;
}
.confirmDialog {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: rgba(0, 0, 0, 0.9);
  height: 100%;
  padding: 0px 50px;
}
.confirmDialog .icn {
  width: 60px;
  height: 60px;
}
.confirmDialog .title {
  font-size: 18px;
  font-weight: 600;
  margin-top: 40px;
}
.confirmDialog .tip {
  font-size: 14px;
  margin-top: 10px;
}
.confirmDialog .loading {
  width: 200px;
  height: 200px;
  margin-top: 43px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.confirmDialog .image {
  width: 200px;
  height: 200px;
  margin-top: 43px;
}
.confirmDialog .guide {
  width: 100%;
  margin-top: 60px;
}
.confirmDialog .desc {
  margin-top: 30px;
  font-size: 14px;
}
.confirmDialog .desc p {
  margin-bottom: 10px;
}
.confirmDialog .desc p:last-child {
  margin-bottom: 0px;
}
.confirmDialog .btns {
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.confirmDialog .btns .btn {
  width: 144px;
  height: 48px;
  border-radius: 4px;
  border: solid 2px #ff6246;
  background-color: #ff6246;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
  cursor: pointer;
}
.confirmDialog .btns .btn:hover {
  opacity: 0.8;
}
.decorate-status {
  display: flex;
  align-items: center;
  margin-left: 12px;
}
.decorate-status .stopService {
  font-size: 12px;
  color: #555555;
  cursor: pointer;
  flex: 0 0 auto;
  line-height: 1;
  text-decoration: underline;
}
.renocation-page-header {
  margin-left: 40px;
  flex: 1 1 auto;
  display: flex;
  align-items: center;
}
.renocation-page-header .back {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.9);
  flex: 0 0 auto;
  display: flex;
  align-items: center;
}
.renocation-page-header .back img {
  width: 9px;
  height: 14px;
  margin-right: 8px;
}
.renocation-page-header .title {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}
.renocation-page-header .title .icn {
  width: 10px;
  height: 10px;
  cursor: pointer;
}
.renocation-page-header .title .value {
  border: none;
  text-align: right;
  width: 300px;
  margin-left: -150px;
  box-shadow: none;
}
