.product {
  border-radius: 6px;
  border: solid 1px #f5f5f5;
  background-color: #ffffff;
  padding-bottom: 15px;
}
.product .cover {
  width: 100%;
  background: #ffffff;
  border-radius: 6px 6px 0px 0px;
}
.product .name {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.9);
  line-height: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  padding: 0px 7px;
  margin-top: 10px;
}
.product .price {
  font-size: 15px;
  font-weight: 600;
  color: #ec4b30;
  line-height: 1.07;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  padding: 0px 7px;
  margin-top: 10px;
}
.product .buyBtn {
  width: 72px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  background-color: #ec4b30;
  color: #ffffff;
  line-height: 1;
  font-size: 13px;
  font-weight: 600;
  margin: 10px auto 0px;
}
