@import '~antd/dist/antd.less';
@import '~rodal/lib/rodal.css';

[data-theme='compact'] .site-collapse-custom-collapse .site-collapse-custom-panel,
.site-collapse-custom-collapse .site-collapse-custom-panel {
  margin-bottom: 24px;
  border: 0px;
  overflow: hidden;
  border-radius: 4px;
  border: solid 1px rgb(240, 234, 218);
  background-color: rgb(255, 248, 230);
  font-size: 14px;
  color: rgba(0, 0, 0, 0.9);
  line-height: 1.5;
}

.rodal{
  z-index: 1000;
}
@primary-color: #ff6246;