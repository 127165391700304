.activity-lottery-record {
  height: calc(100vh - var(--header-height));
  background: #ebedf0;
  padding: 30px 0px 73px;
  overflow-y: scroll;
}
.activity-lottery-record .content {
  width: 95.2rem;
  margin: 0px auto;
  padding: 0px 20px 80px;
}
.activity-lottery-record .content .overview {
  background: #ffffff;
  border-radius: 8px;
  border: solid 1px rgba(0, 0, 0, 0.04);
  padding: 20px 25px;
  font-size: 14px;
  line-height: 1;
  display: flex;
  align-items: center;
}
.activity-lottery-record .content .overview .analyse-icn {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}
.activity-lottery-record .content .overview h2 {
  font-weight: 600;
  color: #d80c0c;
  margin: 0px;
  padding-right: 20px;
  border-right: 1px solid #d80c0c;
  margin-right: 20px;
}
.activity-lottery-record .content .overview .count {
  margin-right: 40px;
}
.activity-lottery-record .content .overview .count .label {
  color: rgba(0, 0, 0, 0.5);
  margin-right: 10px;
}
.activity-lottery-record .content .overview .count .value {
  font-weight: 600;
}
.activity-lottery-record .content .overview .pending {
  flex: 1 1 auto;
}
.activity-lottery-record .content .records {
  margin-top: 12px;
  background: #ffffff;
  border-radius: 8px;
  border: solid 1px rgba(0, 0, 0, 0.04);
  padding: 20px 25px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.9);
}
.activity-lottery-record .content .records .user {
  display: flex;
}
.activity-lottery-record .content .records .user .avatar {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}
