.login{
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(237, 237, 237);
}

.login.hide{
  filter: opacity(0) blur(0.24em);
  transform: scale(1.5);
  transition: all ease 1s 0.5s;
}

.nocode{
  text-align: center;
  width: 100%;
  padding: 20px 0px;
  font-size: 14px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.15);
  letter-spacing: 2px;
  overflow: hidden;
  font-size: 30px;
  font-family: "PingFang SC Semibold";
  font-weight: bold;
}

.origin{
  transform: translateY(50%);
  filter: opacity(0) blur(0em);
  animation: show 2s ease 1s forwards;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
}


.logo {
  width: 26px;
  height: 26px;
  opacity: 0;
  position: absolute;
  top: 10px;
  left: -30px;
}

.faith{
  transform: translateY(-50%);
  filter: opacity(0) blur(0.24em);
  animation: hide 2s linear forwards;
}


@keyframes show {
  0% {
    filter: opacity(0) blur(0.24em);

  }

  100% {
     filter: opacity(1) blur(0em);
     color: rgba(0, 0, 0, 0.6);
  }
}

@keyframes hide {
  0% {
      filter: opacity(0) blur(0.24em);
  }

  40% {
    filter: opacity(1) blur(0em);
  }

  60% {
    filter: opacity(1) blur(0em);
  }

  100% {
      filter: opacity(0) blur(0.24em);
  }
}

.loginError{
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(237, 237, 237);
}

.loginError .main{
  background-image: url(./pic-bg-wrong@3x.png);
  width: 342px;
  height: 295px;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -20vh;
  cursor: pointer;
}

.loginError .desc{
  margin-top: 50px;
  font-size: 21px;
  line-height: 1.13;
  font-weight: bold;
  text-align: center;
  color: rgba(0, 0, 0, 0.8);
}

.loginError .tip{
  font-size: 18px;
  color: rgba(0, 0, 0, 0.5);
  margin-top: 23px;
}

.loginPage{
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(237, 237, 237);
}

.loginPage img{
  width: 30px;
  height: 30px;
}