.coupon-manage-dialog {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}
.coupon-manage-dialog .loading {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.5);
  z-index: 3;
}
.coupon-manage-dialog .coupons {
  flex: 0 1 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 24px;
  padding: 0px 40px;
  overflow-y: scroll;
}
.coupon-manage-dialog .footer {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 58px;
  padding: 0px 50px;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}
.coupon-manage-dialog .footer .pag {
  display: flex;
  align-items: center;
  font-size: 14px;
}
.coupon-manage-dialog .footer .pag .prev,
.coupon-manage-dialog .footer .pag .next {
  border-radius: 6px;
  border: solid 1px rgba(0, 0, 0, 0.1);
}
.coupon-manage-dialog .footer .pag .num {
  margin: 0px 20px;
  color: rgba(0, 0, 0, 0.66);
}
.rodal-dialog {
  padding: 0px;
}
.ant-table-row.disable .ant-table-cell {
  color: rgba(0, 0, 0, 0.35);
}
