.member-coupon {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 800;
  top: 0px;
  left: 0px;
  z-index: -1;
  transition: z-index ease-out 0.25s;
}
.member-coupon.show {
  z-index: 800;
}
.member-coupon.show .cover {
  opacity: 1;
}
.member-coupon.show .config-block {
  transform: translateX(0px);
}
.member-coupon > .cover {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 801;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.2);
  opacity: 0;
  transition: opacity ease-out 0.25s;
  cursor: pointer;
}
.member-coupon .config-block {
  position: absolute;
  z-index: 802;
  right: 0px;
  top: 0px;
  height: 100%;
  width: 400px;
  background-color: #ffffff;
  padding: 77px 0px 0px;
  transform: translateX(400px);
  transition: transform ease-out 0.25s;
  display: flex;
  flex-direction: column;
}
.member-coupon .config-block .module-config-panel {
  flex: 1 1 auto;
  padding: 0px 30px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.member-coupon .config-block .module-config-panel::-webkit-scrollbar {
  display: none;
}
.member-coupon .config-block .footer {
  height: 71px;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}
.member-coupon .config-block .footer .opt {
  border-radius: 6px;
  margin-right: 20px;
  width: 100px;
  height: 38px;
}
.member-coupon .config-block .footer .opt:last-child {
  margin-right: 0px;
}
