:root {
    --header-height: 60px;
}

.basic-layout {
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.basic-layout-header {
    padding: 0px 20px;
    flex: 0 0 auto;
    height: var(--header-height);
    display: flex;
    align-items: center;
    box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.04);
    border-bottom: solid 1px rgba(0, 0, 0, 0.04);
    background-color: rgb(255, 255, 255);
    overflow-x: scroll;
}

.basic-layout-body {
    flex: 1 1 auto;
    overflow-y: scroll;
}