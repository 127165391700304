.member-coupon-task {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 800;
  top: 0px;
  left: 0px;
  z-index: -1;
  transition: z-index ease-out 0.25s;
}
.member-coupon-task.show {
  z-index: 800;
}
.member-coupon-task.show .cover {
  opacity: 1;
}
.member-coupon-task.show .config-block {
  transform: translateX(0px);
}
.member-coupon-task > .cover {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 801;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.2);
  opacity: 0;
  transition: opacity ease-out 0.25s;
  cursor: pointer;
}
.member-coupon-task .config-block {
  position: absolute;
  z-index: 802;
  right: 0px;
  top: 0px;
  height: 100%;
  width: 700px;
  background-color: #ffffff;
  transform: translateX(700px);
  transition: transform ease-out 0.25s;
  display: flex;
}
.member-coupon-task .config-block .module-config-panel {
  flex: 1 1 auto;
  padding: 0px 30px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.member-coupon-task .config-block .module-config-panel::-webkit-scrollbar {
  display: none;
}
.member-coupon-task .config-block .left-block {
  width: 300px;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: #f8f8f8;
  padding: 42px 20px 0px;
  flex: 0 0 auto;
}
.member-coupon-task .config-block .left-block .header {
  font-size: 16px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.9);
  line-height: 1;
}
.member-coupon-task .config-block .left-block .list {
  margin-top: 20px;
}
.member-coupon-task .config-block .left-block .list .item {
  padding: 16px 16px 16px 20px;
  border-radius: 8px;
  display: flex;
  background-color: #ffffff;
  align-items: center;
  cursor: pointer;
  position: relative;
  margin-bottom: 12px;
}
.member-coupon-task .config-block .left-block .list .item.active {
  background-color: #fff1cb;
}
.member-coupon-task .config-block .left-block .list .item:hover .delete-btn {
  display: block;
}
.member-coupon-task .config-block .left-block .list .item .delete-btn {
  display: none;
  width: 24px;
  height: 24px;
  position: absolute;
  top: -6px;
  right: -6px;
}
.member-coupon-task .config-block .left-block .list .item .delete-btn:hover {
  opacity: 0.8;
}
.member-coupon-task .config-block .left-block .list .item .block {
  flex: 1 1 auto;
}
.member-coupon-task .config-block .left-block .list .item .block .name {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.9);
}
.member-coupon-task .config-block .left-block .list .item .block .info {
  font-weight: 600;
  font-size: 14px;
}
.member-coupon-task .config-block .left-block .list .item .block .info .value {
  font-size: 24px;
  position: relative;
  bottom: -1px;
  margin-right: 4px;
}
.member-coupon-task .config-block .left-block .list .item .block .info .desc {
  margin-right: 4px;
}
.member-coupon-task .config-block .left-block .list .item .arrow {
  width: 10px;
  transform: rotate(-90deg);
}
.member-coupon-task .config-block .left-block .new-btn {
  height: 60px;
  border-radius: 8px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
  cursor: pointer;
}
.member-coupon-task .config-block .left-block .new-btn .icn {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}
.member-coupon-task .config-block .left-block .new-btn .desc {
  font-size: 14px;
  font-weight: 600;
  color: #549f06;
}
.member-coupon-task .config-block .left-block .new-btn:hover {
  opacity: 0.8;
}
.member-coupon-task .config-block .right-block {
  display: flex;
  flex-direction: column;
  padding: 77px 0px 0px;
  flex: 0 0 auto;
  width: 400px;
}
.member-coupon-task .config-block .footer {
  height: 71px;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}
.member-coupon-task .config-block .footer .opt {
  border-radius: 6px;
  margin-right: 20px;
  width: 100px;
  height: 38px;
}
.member-coupon-task .config-block .footer .opt:last-child {
  margin-right: 0px;
}
