.renocation-member {
  height: calc(100vh - var(--header-height));
  background: #ebedf0;
  padding: 30px 0px 73px;
  overflow-y: scroll;
}
.renocation-member .content {
  width: 95.2rem;
  margin: 0px auto;
  padding: 0px 20px 80px;
}
.renocation-member .interests {
  padding: 20px;
  border-radius: 8px;
  border: solid 1px rgba(0, 0, 0, 0.04);
  background-color: #ffffff;
}
.renocation-member .interests .header {
  display: flex;
  align-items: center;
  margin: 0px 10px;
  line-height: 1;
}
.renocation-member .interests .header .label {
  font-size: 14px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.9);
}
.renocation-member .interests .header .desc {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
  margin-left: 10px;
}
.renocation-member .interests .items {
  margin-top: 20px;
  border-radius: 8px;
  border: solid 1px rgba(0, 0, 0, 0.05);
  background-color: #f5f6f7;
}
.renocation-member .interests .items .item {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.04);
}
.renocation-member .interests .items .item:last-child {
  border-bottom: none;
}
.renocation-member .interests .items .item .pending {
  flex: 1 1 auto;
}
.renocation-member .interests .items .item .interest {
  display: flex;
  align-items: center;
  width: 120px;
}
.renocation-member .interests .items .item .interest .icn {
  width: 20px;
  height: 20px;
}
.renocation-member .interests .items .item .interest .name {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.9);
  margin-left: 8px;
}
.renocation-member .interests .items .item .opts {
  display: flex;
  align-items: center;
}
.renocation-member .interests .items .item .opts .opt {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 148px;
  height: 32px;
  border-radius: 6px;
  border: solid 1px #e5e5e5;
  background-color: #ffffff;
  margin-right: 12px;
  font-size: 12px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.9);
  cursor: pointer;
}
.renocation-member .interests .items .item .opts .opt .icn {
  width: 14px;
  height: 14px;
  margin-right: 6px;
}
.renocation-member .interests .items .item .opts .opt .arrow {
  width: 10px;
  margin-left: 2px;
  transform: rotate(-90deg);
}
.renocation-member .interests .items .item .opts .opt:hover {
  opacity: 0.8;
}
.renocation-member .interests .items .item .opts .opt.orange {
  background-color: #ff6246;
  color: #ffffff;
}
.renocation-member .interests .items .item .opts .opt.disable {
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.3);
}
.renocation-member .interests .items .item .opts .opt.disable:hover {
  opacity: 1;
}
.renocation-member .records {
  margin-top: 10px;
  background: #ffffff;
  border-radius: 8px;
  border: solid 1px rgba(0, 0, 0, 0.04);
  padding: 20px 25px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.9);
  min-height: 55vh;
}
.renocation-member .records .user {
  display: flex;
}
.renocation-member .records .user .avatar {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}
.renocation-member .records .action-block a {
  margin-right: 30px;
}
.renocation-member .records .action-block a:last-child {
  margin-right: 0px;
}
.renocation-member .records .action-block a.green {
  color: #529b1b;
}
.renocation-member .records .action-block a.gray {
  color: rgba(0, 0, 0, 0.5);
}
.member-renew-input {
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: solid 1px rgba(0, 0, 0, 0.1);
  width: 148px;
  height: 40px;
  padding: 0px 12px;
  justify-content: space-between;
}
.member-level-tooltip .ant-tooltip-inner {
  color: rgba(0, 0, 0, 0.9);
  white-space: pre-line;
  font-size: 12px;
  padding: 16px;
  width: 304px;
}
